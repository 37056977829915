import { SearchTrackingHelper } from './search-tracking-helper';
import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectSearchResult {
	static get ITEM_SELECTOR() {
		return '.audi-j-search-result';
	}

	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectSearchResult._instance) {
			return ClickEventObjectSearchResult._instance;
		}

		clickEventService.register(
			ClickEventObjectSearchResult.getTrackingData,
		);

		ClickEventObjectSearchResult._instance = this;
	}

	/**
	 * getTrackingData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getTrackingData(eventData_) {
		if (!ClickEventObjectSearchResult._isSearchResultItem(eventData_)) {
			return {};
		}

		let trackingDataObject = {
			attributes: {
				pos: ClickEventObjectSearchResult._getSearchResultPosition(
					eventData_,
				),
				value: SearchTrackingHelper.getSearchTerm(),
			},
			eventInfo: {
				eventName: 'main search - result click',
			},
		};

		return trackingDataObject;
	}

	/**
	 * Returns true if the clicked link is placed within a search result item. Else: false
	 * @param {Object} eventData_ - event data
	 * @returns {boolean} indicates whether the clicked link is placed within a search result item
	 * @private
	 */
	static _isSearchResultItem(eventData_) {
		const searchResultElement = eventData_.currentTarget.closest(
			ClickEventObjectSearchResult.ITEM_SELECTOR,
		);

		return searchResultElement !== null;
	}

	/**
	 * Returns the position of the clicked search result item in the list
	 * @param {Object} eventData_ - event data
	 * @returns {string} search term position
	 * @private
	 */
	static _getSearchResultPosition(eventData_) {
		const searchResultElement = eventData_.currentTarget.closest(
			ClickEventObjectSearchResult.ITEM_SELECTOR,
		);
		const resultPosAttribute =
			searchResultElement.getAttribute('data-tracking-pos');

		if (!resultPosAttribute) {
			return '1';
		}

		return resultPosAttribute;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectSearchResult}
 * @public
 * @static
 */
const clickEventObjectSearchResult = new ClickEventObjectSearchResult();
export { clickEventObjectSearchResult, ClickEventObjectSearchResult };
