import { ClickEventHelperDom } from 'core-tracking-objects';
import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectSearchSubmit {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectSearchSubmit._instance) {
			return ClickEventObjectSearchSubmit._instance;
		}

		clickEventService.register(ClickEventObjectSearchSubmit.getData);

		ClickEventObjectSearchSubmit._instance = this;
	}

	/**
	 * getData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getData(eventData_) {
		if (!ClickEventObjectSearchSubmit._isSearchSubmitLink(eventData_)) {
			return {};
		}
		const searchUrl = ClickEventHelperDom.getTargetUrl(eventData_);
		const searchStringPrefix = '#search=';
		const targetIndex =
			searchUrl.indexOf(searchStringPrefix) + searchStringPrefix.length;
		const searchTerm = searchUrl.substring(targetIndex);

		return {
			attributes: {
				componentName: 'main search',
				value: searchTerm,
			},
			eventInfo: {
				eventName: 'main search - submit',
			},
		};
	}

	/**
	 * checks whether an href is aa submit search link link
	 * @param {Event} event_ - an href attribute value
	 * @returns {boolean} - true if it is a submit search link
	 */
	static _isSearchSubmitLink(event_) {
		const searchSubmitClassList =
			ClickEventHelperDom.getTargetClassList(event_);

		if (!searchSubmitClassList) {
			return false;
		}

		return searchSubmitClassList.contains('nm-j-tracking-search-submit');
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectSearchSubmit}
 * @private
 * @static
 */

const clickEventObjectSearchSubmit = new ClickEventObjectSearchSubmit();

export { clickEventObjectSearchSubmit, ClickEventObjectSearchSubmit };
