import { SearchTrackingHelper } from './search-tracking-helper';
import { polling } from '../../../js/utils-bundle';
import { searchService } from 'core-tracking-objects';

class SearchObjectGsaSearch {
	static get POLL_TIME() {
		return '2000';
	}

	/**
	 * registers to SearchService
	 * @constructor singleton
	 */
	constructor() {
		if (SearchObjectGsaSearch._instance) {
			return SearchObjectGsaSearch._instance;
		}

		this.callBackFunction = this.getTrackingData.bind(this);

		searchService.register(this.callBackFunction);

		SearchObjectGsaSearch._instance = this;
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @public
	 * @returns {Promise} promise - returns promise for searchObject
	 */
	async getTrackingData() {
		if (!SearchTrackingHelper.isSearchResultPageGSA()) {
			return {};
		}

		try {
			await polling.wait(
				this._isBodyWithSearchParameters,
				SearchObjectGsaSearch.POLL_TIME,
			);

			return {
				name: 'main search',
				results: document
					.querySelector('body')
					.getAttribute('data-search-results'),
				term: SearchTrackingHelper.getSearchTerm(),
			};
		} catch (e) {
			return {};
		}
	}

	/**
	 * _isBodyWithSearchParameters - checks if body tag has search data attributes
	 * @private
	 * @returns {boolean} returns true if body tag has search data attributes
	 */
	_isBodyWithSearchParameters() {
		return document
			.querySelector('body')
			.hasAttribute('data-search-results');
	}
}

/**
 * Singleton instance
 * @type {SearchObjectGsaSearch}
 * @public
 * @static
 */
const searchObjectGsaSearch = new SearchObjectGsaSearch();
export { searchObjectGsaSearch, SearchObjectGsaSearch };
