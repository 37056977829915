import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectSearchAccess {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectSearchAccess._instance) {
			return ClickEventObjectSearchAccess._instance;
		}

		clickEventService.register(
			ClickEventObjectSearchAccess.getTrackingData,
		);

		ClickEventObjectSearchAccess._instance = this;
	}

	/**
	 * getTrackingData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getTrackingData(eventData_) {
		if (!ClickEventObjectSearchAccess._isSearchAccess(eventData_)) {
			return {};
		}

		let trackingDataObject = {
			attributes: {
				pos: '',
				targetURL: '',
				value: '',
			},
			eventInfo: {
				eventAction: 'navigation',
				eventName: 'main search - access',
			},
		};

		return trackingDataObject;
	}

	static _isSearchAccess(eventData_) {
		return (
			eventData_ &&
			eventData_.currentTarget &&
			eventData_.currentTarget.classList &&
			eventData_.currentTarget.classList.contains(
				'audi-j-search-access',
			) &&
			eventData_.currentTarget.parentElement &&
			eventData_.currentTarget.parentElement.classList.contains(
				'nm-header-overlay-is-open',
			)
		);
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectSearchAccess}
 * @public
 * @static
 */
const clickEventObjectSearchAccess = new ClickEventObjectSearchAccess();
export { clickEventObjectSearchAccess, ClickEventObjectSearchAccess };
