import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectQuickSearchResult {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectQuickSearchResult._instance) {
			return ClickEventObjectQuickSearchResult._instance;
		}

		clickEventService.register(
			ClickEventObjectQuickSearchResult.getTrackingData,
		);

		ClickEventObjectQuickSearchResult._instance = this;
	}

	/**
	 * getTrackingData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getTrackingData(eventData_) {
		if (
			!eventData_ ||
			!eventData_.currentTarget ||
			!eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains(
				'audi-j-quick-search-result-tracking',
			)
		) {
			return {};
		}

		return {
			attributes: {
				componentName: 'main search',
				pos: ClickEventObjectQuickSearchResult._getSearchResultPosition(
					eventData_.currentTarget,
				),
				value: ClickEventObjectQuickSearchResult._getSearchTerm(),
			},
			eventInfo: {
				eventName: 'main search - quick search result click',
			},
		};
	}

	/**
	 * Returns the search term of the current search
	 * @returns {string} search term
	 * @private
	 */
	static _getSearchTerm() {
		let searchTerm = '';

		if (document.querySelector('input.nm-su-input')) {
			searchTerm = document.querySelector('input.nm-su-input').value;
		}

		return searchTerm;
	}

	/**
	 * Returns the search term position within the containing list or 1 if not in list
	 * @param {Object} eventTarget_ clicked search item
	 * @returns {string} position
	 * @private
	 */
	static _getSearchResultPosition(eventTarget_) {
		let index = 1;

		if (!eventTarget_) {
			return index.toString();
		}

		const searchResultElement = eventTarget_.closest('li');

		if (searchResultElement && searchResultElement.parentNode) {
			index = [...searchResultElement.parentNode.children].indexOf(
				searchResultElement,
			);
			index += 1; // as per spec index starts with 1
		}

		return index.toString();
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectQuickSearchResult}
 * @public
 * @static
 */
const clickEventObjectQuickSearchResult =
	new ClickEventObjectQuickSearchResult();
export { clickEventObjectQuickSearchResult, ClickEventObjectQuickSearchResult };
