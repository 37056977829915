class SearchTrackingHelper {
	/**
	 * Returns the search term from the body attribute
	 * @returns {string} search term
	 */
	static getSearchTerm() {
		return (
			SearchTrackingHelper._getSearchTermGSA() ||
			this._getSearchTermSiteSearch()
		);
	}
	/**
	 * Returns the search term from the body attribute
	 * @returns {string} search term
	 */
	static _getSearchTermGSA() {
		return document.body.getAttribute('data-search-term') || '';
	}
	/**
	 * Returns the search term from the search query string
	 * @returns {string} search term
	 */
	static _getSearchTermSiteSearch() {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get('q') || '';
	}

	/**
	 * isSearchResultPage - tests for search result page
	 * @returns {boolean} returns true if current page is a search result page
	 */
	static isSearchResultPage() {
		return (
			SearchTrackingHelper.isSearchResultPageGSA() ||
			SearchTrackingHelper.isSearchResultPageSiteSearch()
		);
	}

	/**
	 * isSearchResultPageGSA - tests for search result page css class
	 * @returns {boolean} returns true if css class for gsa search result page exists
	 */
	static isSearchResultPageGSA() {
		return document.querySelectorAll('.gsa-search-result').length !== 0;
	}

	/**
	 * isSearchResultPage - tests for search result page custom element
	 * @returns {boolean} returns true if site-search custom element exists
	 */
	static isSearchResultPageSiteSearch() {
		return document.querySelectorAll('site-search').length !== 0;
	}
}

export { SearchTrackingHelper };
