import { clickEventObjectLayerOpen } from 'core-tracking-objects';
import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectLayerOpenNemo {
	constructor() {
		if (ClickEventObjectLayerOpenNemo._instance) {
			return ClickEventObjectLayerOpenNemo._instance;
		}

		this._registerAdditionalClickSelectors();
		this._registerLayerOpenSelectors();

		ClickEventObjectLayerOpenNemo._instance = this;
	}

	_registerAdditionalClickSelectors() {
		clickEventService.registerClickSelector('.nm-jslayerLink');
		clickEventService.registerClickSelector('.nm-layerLink');
	}

	_registerLayerOpenSelectors() {
		clickEventObjectLayerOpen.registerLayerOpenSelector('.nm-jslayerLink');
		clickEventObjectLayerOpen.registerLayerOpenSelector('.nm-layerLink');
	}
}

const clickEventObjectLayerOpenNemo = new ClickEventObjectLayerOpenNemo();
export { clickEventObjectLayerOpenNemo, ClickEventObjectLayerOpenNemo };
