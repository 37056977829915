import { clickEventObjectLayerClose } from 'core-tracking-objects';
import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectLayerCloseNemo {
	constructor() {
		if (ClickEventObjectLayerCloseNemo._instance) {
			return ClickEventObjectLayerCloseNemo._instance;
		}

		this._registerAdditionalClickSelectors();
		this._registerLayerCloseSelectors();

		ClickEventObjectLayerCloseNemo._instance = this;
	}

	_registerAdditionalClickSelectors() {
		clickEventService.registerClickSelector('.nm-layer .nm-button-cancel');
		clickEventService.registerClickSelector(
			'.nm-j-jslayer .nm-button-close',
		);
		clickEventService.registerClickSelector('.nm-layer .nm-button-close');
		clickEventService.registerClickSelector('.nm-j-layer-close-button');
		clickEventService.registerClickSelector('.nm-layerClose');
	}

	_registerLayerCloseSelectors() {
		clickEventObjectLayerClose.registerLayerCloseSelector(
			'.nm-layer .nm-button-cancel',
		);
		clickEventObjectLayerClose.registerLayerCloseSelector(
			'.nm-j-jslayer .nm-button-close',
		);
		clickEventObjectLayerClose.registerLayerCloseSelector(
			'.nm-layer .nm-button-close',
		);
		clickEventObjectLayerClose.registerLayerCloseSelector(
			'.nm-j-layer-close-button',
		);
		clickEventObjectLayerClose.registerLayerCloseSelector('.nm-layerClose');
	}
}

const clickEventObjectLayerCloseNemo = new ClickEventObjectLayerCloseNemo();
export { clickEventObjectLayerCloseNemo, ClickEventObjectLayerCloseNemo };
