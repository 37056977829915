import { SearchTrackingHelper } from './search-tracking-helper';
import { searchService } from 'core-tracking-objects';

class SearchObjectSiteSearch {
	constructor() {
		if (SearchObjectSiteSearch._instance) {
			return SearchObjectSiteSearch._instance;
		}

		const trackingDataCallbackFunction = this.getTrackingData.bind(this);

		searchService.register(trackingDataCallbackFunction);
		SearchObjectSiteSearch._instance = this;
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @public
	 * @returns {Promise} promise - returns promise for searchObject
	 */
	async getTrackingData() {
		if (!SearchTrackingHelper.isSearchResultPageSiteSearch()) {
			return {};
		}

		return {
			name: 'main search',
			results: '', // cannot be retrieved without interface
			term: SearchTrackingHelper.getSearchTerm(),
		};
	}
}

const searchObjectSiteSearch = new SearchObjectSiteSearch();
export { searchObjectSiteSearch, SearchObjectSiteSearch };
