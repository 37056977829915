import { SearchTrackingHelper } from './search-tracking-helper';
import { clickEventService } from 'core-tracking-objects';

class ClickEventObjectMoreSearchResults {
	/**
	 * registers to ClickEventService
	 * @constructor singleton
	 */
	constructor() {
		if (ClickEventObjectMoreSearchResults._instance) {
			return ClickEventObjectMoreSearchResults._instance;
		}

		clickEventService.register(
			ClickEventObjectMoreSearchResults.getTrackingData,
		);

		ClickEventObjectMoreSearchResults._instance = this;
	}

	/**
	 * getTrackingData - collects the tracking data
	 * @param {Object} eventData_ - event data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getTrackingData(eventData_) {
		const target = eventData_.currentTarget;

		if (
			!ClickEventObjectMoreSearchResults._isPageNumberItem(target) &&
			!ClickEventObjectMoreSearchResults._isNextPageItem(target) &&
			!ClickEventObjectMoreSearchResults._isPreviousPageItem(target)
		) {
			return {};
		}

		let trackingDataObject = {
			attributes: {
				componentName: 'main search',
				pos: ClickEventObjectMoreSearchResults._getPageNumber(target),
				value: SearchTrackingHelper.getSearchTerm(),
			},
			eventInfo: {
				eventAction: 'navigation',
				eventName: 'main search - more search results',
			},
		};

		return trackingDataObject;
	}

	/**
	 * Returns true if the clicked link is placed within a search result item. Else: false
	 * @param {Object} target_ - event target
	 * @returns {boolean} indicates whether the clicked link is placed within a search result item
	 * @private
	 */
	static _isPageNumberItem(target_) {
		if (
			!target_ ||
			!target_.parentNode ||
			!target_.parentNode.parentNode ||
			!target_.parentNode.parentNode.classList
		) {
			return false;
		}

		return target_.parentNode.parentNode.classList.contains(
			'lists-pagination',
		);
	}

	/**
	 * Returns true if the clicked link is the "previous page" item in the pagination list. Else: false
	 * @param {Object} target_ - event target
	 * @returns {boolean} indicates whether the clicked link is the "previous page" item
	 * @private
	 */
	static _isPreviousPageItem(target_) {
		if (
			!target_ ||
			!target_.parentNode ||
			!target_.parentNode.classList ||
			!target_.classList
		) {
			return false;
		}

		return (
			target_.parentNode.classList.contains('navigation-pagination') &&
			target_.classList.contains('prev')
		);
	}

	/**
	 * Returns true if the clicked link is the "next page" item in the pagination list. Else: false
	 * @param {Object} target_ - event target
	 * @returns {boolean} indicates whether the clicked link is the "next page" item
	 * @private
	 */
	static _isNextPageItem(target_) {
		if (
			!target_ ||
			!target_.parentNode ||
			!target_.parentNode.classList ||
			!target_.classList
		) {
			return false;
		}

		return (
			target_.parentNode.classList.contains('navigation-pagination') &&
			target_.classList.contains('next')
		);
	}

	/**
	 * Returns the position of the clicked search pagination item
	 * @param {Object} target_ - event target
	 * @returns {string} search pagination position
	 * @private
	 */
	static _getPageNumber(target_) {
		if (ClickEventObjectMoreSearchResults._isPageNumberItem(target_)) {
			return target_.innerText;
		}

		const listPaginationSelection =
			ClickEventObjectMoreSearchResults._getListPaginationSelection();

		if (ClickEventObjectMoreSearchResults._isNextPageItem(target_)) {
			return listPaginationSelection
				? (Number(listPaginationSelection.innerText) + 1).toString()
				: '0';
		}

		if (ClickEventObjectMoreSearchResults._isPreviousPageItem(target_)) {
			return listPaginationSelection
				? (Number(listPaginationSelection.innerText) - 1).toString()
				: '0';
		}

		return '0';
	}

	/**
	 * get list pagination selection
	 * @return {Element|null} - selection result
	 */
	static _getListPaginationSelection() {
		return document.querySelector('.lists-pagination .is-selected a');
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectMoreSearchResults}
 * @public
 * @static
 */
const clickEventObjectMoreSearchResults =
	new ClickEventObjectMoreSearchResults();
export { clickEventObjectMoreSearchResults, ClickEventObjectMoreSearchResults };
