import { SearchTrackingHelper } from './search-tracking-helper';
import { stateEventService } from 'core-tracking-objects';

class StateEventObjectSearchResult {
	/**
	 * registers to StateEventService
	 * @constructor singleton
	 */
	constructor() {
		if (StateEventObjectSearchResult._instance) {
			return StateEventObjectSearchResult._instance;
		}

		stateEventService.register(
			StateEventObjectSearchResult.getTrackingData,
		);

		StateEventObjectSearchResult._instance = this;
	}

	/**
	 * getTrackingData - collects the tracking data
	 * @returns {Promise} promise - returns promise for linkObject
	 */
	static async getTrackingData() {
		if (!SearchTrackingHelper.isSearchResultPage()) {
			return {};
		}

		let trackingDataObject = {
			eventInfo: {
				eventName: 'main search - results',
			},
		};

		return trackingDataObject;
	}
}

/**
 * Singleton instance
 * @type {StateEventObjectSearchResult}
 * @public
 * @static
 */
const stateEventObjectSearchResult = new StateEventObjectSearchResult();
export { stateEventObjectSearchResult, StateEventObjectSearchResult };
